import { Union, Record } from "../fable_modules/fable-library-js.4.17.0/Types.js";
import { DataServer_InsiderTrade_InsiderTradeEventPercentage_$reflection, DataServer_InsiderTrade_InsiderTradeEventValuation_$reflection, DataServer_InsiderTrade_InsiderTradeSummary_$reflection } from "../Shared/ApiDataTypes.js";
import { obj_type, union_type, record_type, string_type, array_type, option_type } from "../fable_modules/fable-library-js.4.17.0/Reflection.js";
import { FetchError, FetchError_$reflection } from "../fable_modules/Thoth.Fetch.3.0.1/Fetch.fs.js";
import { FSharpResult$2 } from "../fable_modules/fable-library-js.4.17.0/Result.js";
import { PromiseBuilder__Delay_62FBFDE1, PromiseBuilder__Run_212F1D4B } from "../fable_modules/Fable.Promise.2.0.0/Promise.fs.js";
import { promise } from "../fable_modules/Fable.Promise.2.0.0/PromiseImpl.fs.js";
import { Auto_generateBoxedDecoderCached_Z6670B51, Auto_generateBoxedDecoder_Z6670B51 } from "../fable_modules/Thoth.Json.6.0.0/Decode.fs.js";
import { PromiseBuilder__Delay_62FBFDE1 as PromiseBuilder__Delay_62FBFDE1_1, PromiseBuilder__Run_212F1D4B as PromiseBuilder__Run_212F1D4B_1 } from "../fable_modules/Fable.Promise.2.0.0/Promise.fs.js";
import { promise as promise_1 } from "../fable_modules/Fable.Promise.2.0.0/PromiseImpl.fs.js";
import { Helper_fetch, Helper_withContentTypeJson, Helper_withProperties } from "../fable_modules/Thoth.Fetch.3.0.1/Fetch.fs.js";
import { singleton as singleton_1, cons, ofArray, empty } from "../fable_modules/fable-library-js.4.17.0/List.js";
import { Types_RequestProperties } from "../fable_modules/Fable.Fetch.2.1.0/Fetch.fs.js";
import { keyValueList } from "../fable_modules/fable-library-js.4.17.0/MapUtil.js";
import { unwrap, map, defaultArg } from "../fable_modules/fable-library-js.4.17.0/Option.js";
import { toString } from "../fable_modules/Thoth.Json.6.0.0/Encode.fs.js";
import { Auto_generateBoxedEncoderCached_437914C6 } from "../fable_modules/Thoth.Json.6.0.0/Encode.fs.js";
import { DataServerBaseUrl } from "../Session.js";
import { fromString } from "../fable_modules/Thoth.Json.6.0.0/Decode.fs.js";
import { comparePrimitives, int32ToString, createObj, uncurry2 } from "../fable_modules/fable-library-js.4.17.0/Util.js";
import { createElement } from "react";
import React from "react";
import { Dimensions_TextExtraSmall, Dimensions_TextSmall } from "../Design.js";
import { Interop_reactApi } from "../fable_modules/Feliz.2.7.0/Interop.fs.js";
import { shortPercentageToString, utcDateToLocalDate, toShortAmountL, toPercentageChange } from "../Utils.js";
import { format } from "../fable_modules/fable-library-js.4.17.0/String.js";
import { singleton, append, delay, toList } from "../fable_modules/fable-library-js.4.17.0/Seq.js";
import { LoadingIndicator_assetListEntryLoading, LoadingIndicator_skeleton, wrapTooltip } from "../ViewHelpers.js";
import { fromInt32, op_Multiply, toInt64 } from "../fable_modules/fable-library-js.4.17.0/BigInt.js";
import { assetNameColumn, td$0027, sectionTitle, section } from "./Short.js";
import { sortByDescending, map as map_1 } from "../fable_modules/fable-library-js.4.17.0/Array.js";
import { useReact_useEffectOnce_3A5B6456, useReact_useReducer_2B9E6EA0 } from "../fable_modules/Feliz.2.7.0/React.fs.js";
import { Target, PostFeed } from "../Content/PostFeed.js";
import { InsiderTradeOverview } from "../Content/Ads.js";

class Model extends Record {
    constructor(Summary, LargestTransactions, LargestPercentages, ErrorMsg) {
        super();
        this.Summary = Summary;
        this.LargestTransactions = LargestTransactions;
        this.LargestPercentages = LargestPercentages;
        this.ErrorMsg = ErrorMsg;
    }
}

function Model_$reflection() {
    return record_type("Investfora.InsiderTrades.Model", [], Model, () => [["Summary", option_type(DataServer_InsiderTrade_InsiderTradeSummary_$reflection())], ["LargestTransactions", option_type(array_type(DataServer_InsiderTrade_InsiderTradeEventValuation_$reflection()))], ["LargestPercentages", option_type(array_type(DataServer_InsiderTrade_InsiderTradeEventPercentage_$reflection()))], ["ErrorMsg", option_type(string_type)]]);
}

class Message extends Union {
    constructor(tag, fields) {
        super();
        this.tag = tag;
        this.fields = fields;
    }
    cases() {
        return ["SummaryResponse", "TransactionsResponse", "PercentagesResponse", "DismissError"];
    }
}

function Message_$reflection() {
    return union_type("Investfora.InsiderTrades.Message", [], Message, () => [[["Item", union_type("Microsoft.FSharp.Core.FSharpResult`2", [DataServer_InsiderTrade_InsiderTradeSummary_$reflection(), FetchError_$reflection()], FSharpResult$2, () => [[["ResultValue", DataServer_InsiderTrade_InsiderTradeSummary_$reflection()]], [["ErrorValue", FetchError_$reflection()]]])]], [["Item", union_type("Microsoft.FSharp.Core.FSharpResult`2", [array_type(DataServer_InsiderTrade_InsiderTradeEventValuation_$reflection()), FetchError_$reflection()], FSharpResult$2, () => [[["ResultValue", array_type(DataServer_InsiderTrade_InsiderTradeEventValuation_$reflection())]], [["ErrorValue", FetchError_$reflection()]]])]], [["Item", union_type("Microsoft.FSharp.Core.FSharpResult`2", [array_type(DataServer_InsiderTrade_InsiderTradeEventPercentage_$reflection()), FetchError_$reflection()], FSharpResult$2, () => [[["ResultValue", array_type(DataServer_InsiderTrade_InsiderTradeEventPercentage_$reflection())]], [["ErrorValue", FetchError_$reflection()]]])]], []]);
}

function init() {
    return new Model(undefined, undefined, undefined, undefined);
}

function update(model, msg) {
    switch (msg.tag) {
        case 1: {
            const res_1 = msg.fields[0];
            if (res_1.tag === 1) {
                return new Model(model.Summary, model.LargestTransactions, model.LargestPercentages, "Kunne ikke laste største innsidehandler. Vennligst prøv igjen senere.");
            }
            else {
                return new Model(model.Summary, res_1.fields[0], model.LargestPercentages, model.ErrorMsg);
            }
        }
        case 2: {
            const res_2 = msg.fields[0];
            if (res_2.tag === 1) {
                return new Model(model.Summary, model.LargestTransactions, model.LargestPercentages, "Kunne ikke laste største innsidehandler. Vennligst prøv igjen senere.");
            }
            else {
                return new Model(model.Summary, model.LargestTransactions, res_2.fields[0], model.ErrorMsg);
            }
        }
        case 3:
            return new Model(model.Summary, model.LargestTransactions, model.LargestPercentages, undefined);
        default: {
            const res = msg.fields[0];
            if (res.tag === 1) {
                return new Model(model.Summary, model.LargestTransactions, model.LargestPercentages, "Kunne ikke laste innsidehandel oppsummering. Vennligst prøv igjen senere.");
            }
            else {
                return new Model(res.fields[0], model.LargestTransactions, model.LargestPercentages, model.ErrorMsg);
            }
        }
    }
}

function fetchSummary(dispatch) {
    const pr_1 = PromiseBuilder__Run_212F1D4B(promise, PromiseBuilder__Delay_62FBFDE1(promise, () => {
        let decoder, data_1, caseStrategy_2, extra_2;
        return ((decoder = Auto_generateBoxedDecoder_Z6670B51(DataServer_InsiderTrade_InsiderTradeSummary_$reflection(), undefined, undefined), (data_1 = undefined, (caseStrategy_2 = undefined, (extra_2 = undefined, (() => {
            let properties_2;
            try {
                const properties_3 = Helper_withProperties(empty(), (properties_2 = ofArray([new Types_RequestProperties(0, ["GET"]), new Types_RequestProperties(1, [keyValueList(Helper_withContentTypeJson(data_1, empty()), 0)])]), defaultArg(map((data_1_1) => cons(new Types_RequestProperties(2, [toString(0, Auto_generateBoxedEncoderCached_437914C6(obj_type, caseStrategy_2, extra_2)(data_1_1))]), properties_2), data_1), properties_2)));
                const pr = PromiseBuilder__Run_212F1D4B_1(promise_1, PromiseBuilder__Delay_62FBFDE1_1(promise_1, () => (Helper_fetch(`${DataServerBaseUrl}/api/insider-trade/summary`, properties_3).then((_arg) => {
                    let response_1, decoder_1_1;
                    return ((response_1 = _arg, (decoder_1_1 = defaultArg(decoder, Auto_generateBoxedDecoderCached_Z6670B51(DataServer_InsiderTrade_InsiderTradeSummary_$reflection(), unwrap(caseStrategy_2), unwrap(extra_2))), PromiseBuilder__Run_212F1D4B_1(promise_1, PromiseBuilder__Delay_62FBFDE1_1(promise_1, () => (((response_1.ok) ? PromiseBuilder__Run_212F1D4B_1(promise_1, PromiseBuilder__Delay_62FBFDE1_1(promise_1, () => (response_1.text().then((_arg_1) => {
                        let matchValue;
                        return Promise.resolve((matchValue = fromString(uncurry2(decoder_1_1), _arg_1), (matchValue.tag === 1) ? (new FSharpResult$2(1, [new FetchError(1, [matchValue.fields[0]])])) : (new FSharpResult$2(0, [matchValue.fields[0]]))));
                    })))) : (Promise.resolve(new FSharpResult$2(1, [new FetchError(2, [response_1])])))).then((_arg_1_1) => (Promise.resolve(_arg_1_1)))))))));
                }))));
                return pr.then(void 0, ((arg) => (new FSharpResult$2(1, [new FetchError(3, [arg])]))));
            }
            catch (exn) {
                return PromiseBuilder__Run_212F1D4B_1(promise_1, PromiseBuilder__Delay_62FBFDE1_1(promise_1, () => (Promise.resolve(new FSharpResult$2(1, [new FetchError(0, [exn])])))));
            }
        })()))))).then((_arg_2) => {
            dispatch(new Message(0, [_arg_2]));
            return Promise.resolve();
        });
    }));
    pr_1.then();
}

function fetchTransactions(dispatch) {
    const pr_1 = PromiseBuilder__Run_212F1D4B(promise, PromiseBuilder__Delay_62FBFDE1(promise, () => {
        let decoder, data_1, caseStrategy_2, extra_2;
        return ((decoder = Auto_generateBoxedDecoder_Z6670B51(array_type(DataServer_InsiderTrade_InsiderTradeEventValuation_$reflection()), undefined, undefined), (data_1 = undefined, (caseStrategy_2 = undefined, (extra_2 = undefined, (() => {
            let properties_2;
            try {
                const properties_3 = Helper_withProperties(empty(), (properties_2 = ofArray([new Types_RequestProperties(0, ["GET"]), new Types_RequestProperties(1, [keyValueList(Helper_withContentTypeJson(data_1, empty()), 0)])]), defaultArg(map((data_1_1) => cons(new Types_RequestProperties(2, [toString(0, Auto_generateBoxedEncoderCached_437914C6(obj_type, caseStrategy_2, extra_2)(data_1_1))]), properties_2), data_1), properties_2)));
                const pr = PromiseBuilder__Run_212F1D4B_1(promise_1, PromiseBuilder__Delay_62FBFDE1_1(promise_1, () => (Helper_fetch(`${DataServerBaseUrl}/api/insider-trade/largest-transactions?period=14d`, properties_3).then((_arg) => {
                    let response_1, decoder_1_1;
                    return ((response_1 = _arg, (decoder_1_1 = defaultArg(decoder, Auto_generateBoxedDecoderCached_Z6670B51(array_type(DataServer_InsiderTrade_InsiderTradeEventValuation_$reflection()), unwrap(caseStrategy_2), unwrap(extra_2))), PromiseBuilder__Run_212F1D4B_1(promise_1, PromiseBuilder__Delay_62FBFDE1_1(promise_1, () => (((response_1.ok) ? PromiseBuilder__Run_212F1D4B_1(promise_1, PromiseBuilder__Delay_62FBFDE1_1(promise_1, () => (response_1.text().then((_arg_1) => {
                        let matchValue;
                        return Promise.resolve((matchValue = fromString(uncurry2(decoder_1_1), _arg_1), (matchValue.tag === 1) ? (new FSharpResult$2(1, [new FetchError(1, [matchValue.fields[0]])])) : (new FSharpResult$2(0, [matchValue.fields[0]]))));
                    })))) : (Promise.resolve(new FSharpResult$2(1, [new FetchError(2, [response_1])])))).then((_arg_1_1) => (Promise.resolve(_arg_1_1)))))))));
                }))));
                return pr.then(void 0, ((arg) => (new FSharpResult$2(1, [new FetchError(3, [arg])]))));
            }
            catch (exn) {
                return PromiseBuilder__Run_212F1D4B_1(promise_1, PromiseBuilder__Delay_62FBFDE1_1(promise_1, () => (Promise.resolve(new FSharpResult$2(1, [new FetchError(0, [exn])])))));
            }
        })()))))).then((_arg_2) => {
            dispatch(new Message(1, [_arg_2]));
            return Promise.resolve();
        });
    }));
    pr_1.then();
}

function fetchPercentages(dispatch) {
    const pr_1 = PromiseBuilder__Run_212F1D4B(promise, PromiseBuilder__Delay_62FBFDE1(promise, () => {
        let decoder, data_1, caseStrategy_2, extra_2;
        return ((decoder = Auto_generateBoxedDecoder_Z6670B51(array_type(DataServer_InsiderTrade_InsiderTradeEventPercentage_$reflection()), undefined, undefined), (data_1 = undefined, (caseStrategy_2 = undefined, (extra_2 = undefined, (() => {
            let properties_2;
            try {
                const properties_3 = Helper_withProperties(empty(), (properties_2 = ofArray([new Types_RequestProperties(0, ["GET"]), new Types_RequestProperties(1, [keyValueList(Helper_withContentTypeJson(data_1, empty()), 0)])]), defaultArg(map((data_1_1) => cons(new Types_RequestProperties(2, [toString(0, Auto_generateBoxedEncoderCached_437914C6(obj_type, caseStrategy_2, extra_2)(data_1_1))]), properties_2), data_1), properties_2)));
                const pr = PromiseBuilder__Run_212F1D4B_1(promise_1, PromiseBuilder__Delay_62FBFDE1_1(promise_1, () => (Helper_fetch(`${DataServerBaseUrl}/api/insider-trade/largest-percentage?period=14d`, properties_3).then((_arg) => {
                    let response_1, decoder_1_1;
                    return ((response_1 = _arg, (decoder_1_1 = defaultArg(decoder, Auto_generateBoxedDecoderCached_Z6670B51(array_type(DataServer_InsiderTrade_InsiderTradeEventPercentage_$reflection()), unwrap(caseStrategy_2), unwrap(extra_2))), PromiseBuilder__Run_212F1D4B_1(promise_1, PromiseBuilder__Delay_62FBFDE1_1(promise_1, () => (((response_1.ok) ? PromiseBuilder__Run_212F1D4B_1(promise_1, PromiseBuilder__Delay_62FBFDE1_1(promise_1, () => (response_1.text().then((_arg_1) => {
                        let matchValue;
                        return Promise.resolve((matchValue = fromString(uncurry2(decoder_1_1), _arg_1), (matchValue.tag === 1) ? (new FSharpResult$2(1, [new FetchError(1, [matchValue.fields[0]])])) : (new FSharpResult$2(0, [matchValue.fields[0]]))));
                    })))) : (Promise.resolve(new FSharpResult$2(1, [new FetchError(2, [response_1])])))).then((_arg_1_1) => (Promise.resolve(_arg_1_1)))))))));
                }))));
                return pr.then(void 0, ((arg) => (new FSharpResult$2(1, [new FetchError(3, [arg])]))));
            }
            catch (exn) {
                return PromiseBuilder__Run_212F1D4B_1(promise_1, PromiseBuilder__Delay_62FBFDE1_1(promise_1, () => (Promise.resolve(new FSharpResult$2(1, [new FetchError(0, [exn])])))));
            }
        })()))))).then((_arg_2) => {
            dispatch(new Message(2, [_arg_2]));
            return Promise.resolve();
        });
    }));
    pr_1.then();
}

function aiInfo() {
    let elems;
    return createElement("div", createObj(ofArray([["style", {
        display: "flex",
        marginTop: 10,
        fontSize: Dimensions_TextSmall,
        fontStyle: "italic",
    }], (elems = [createElement("span", {
        children: ["Denne oppsummeringen er AI-generert av ChatGPT fra børsmeldingen. Med forbehold om feil."],
    })], ["children", Interop_reactApi.Children.toArray(Array.from(elems))])])));
}

function periodIndicator(txt) {
    let elems;
    return createElement("div", createObj(ofArray([["style", {
        borderRadius: 25,
        padding: ((2 + "px ") + 9) + "px",
        fontSize: Dimensions_TextSmall,
        border: (((2 + "px ") + "solid") + " ") + "var(--bg-normal)",
        color: "var(--text-main)",
        marginBottom: 2 + "vh",
        whiteSpace: "nowrap",
    }], (elems = [createElement("span", {
        children: [txt],
    })], ["children", Interop_reactApi.Children.toArray(Array.from(elems))])])));
}

function drawSummary(x) {
    let elems_4, elems_7;
    const highlightedHolder = (children) => createElement("div", {
        style: {
            display: "flex",
            flexDirection: "column",
            textAlign: "center",
            alignItems: "center",
            width: 100 + "%",
            maxWidth: 240,
        },
        children: Interop_reactApi.Children.toArray(Array.from(children)),
    });
    const highlighted = (title, content, currentValue, prevValue, desc) => {
        let x_2, y, elems_1, elems_2;
        let changeColor;
        const x_1 = currentValue | 0;
        const p = prevValue | 0;
        changeColor = ((x_1 > p) ? "var(--buy)" : ((x_1 < p) ? "var(--sell)" : "var(--text-main)"));
        const pctChange = (prevValue === 0) ? "(0%%)" : (` (${(x_2 = toPercentageChange(currentValue, prevValue), (y = format('{0:' + "0.0" + '}', x_2), (x_2 > 0) ? (`+${y}`) : y))}%)`);
        return highlightedHolder([createElement("span", createObj(ofArray([["style", {
            fontSize: Dimensions_TextSmall,
            color: "var(--text-main)",
        }], (elems_1 = [title, createElement("span", {
            style: {
                color: changeColor,
                fontSize: Dimensions_TextSmall,
            },
            children: pctChange,
        })], ["children", Interop_reactApi.Children.toArray(Array.from(elems_1))])]))), createElement("span", createObj(ofArray([["style", {
            fontWeight: "bold",
            fontSize: 1.2 + "rem",
            whiteSpace: "nowrap",
        }], (elems_2 = [createElement("span", {
            children: [content],
        })], ["children", Interop_reactApi.Children.toArray(Array.from(elems_2))])]))), createElement("span", {
            style: {
                fontSize: Dimensions_TextSmall,
                color: "var(--text-dim)",
            },
            children: desc,
        })]);
    };
    const highlightedAsset = (title_1, content_1, desc_1) => highlightedHolder(toList(delay(() => append(singleton(createElement("span", {
        style: {
            fontSize: Dimensions_TextSmall,
            color: "var(--text-main)",
        },
        children: title_1,
    })), delay(() => {
        let elems_3;
        const matchValue = content_1;
        if (matchValue != null) {
            const c = matchValue;
            return append(singleton(createElement("a", createObj(ofArray([["style", {}], ["href", `/stock/${c.Market}/${c.Ticker}`], (elems_3 = [wrapTooltip(c.Name, createElement("span", {
                style: {
                    fontWeight: "bold",
                    fontSize: 1.2 + "rem",
                },
                children: `${c.Ticker}.${c.Market}`,
            }))], ["children", Interop_reactApi.Children.toArray(Array.from(elems_3))])])))), delay(() => singleton(createElement("span", {
                style: {
                    fontSize: Dimensions_TextSmall,
                    color: "var(--text-dim)",
                },
                children: desc_1(c.Type),
            }))));
        }
        else {
            return append(singleton(createElement("span", {
                style: {
                    fontWeight: "bold",
                },
                children: "N/A",
            })), delay(() => singleton(createElement("span", {
                style: {
                    fontSize: Dimensions_TextSmall,
                    color: "var(--text-dim)",
                },
                children: "Ingen for periode",
            }))));
        }
    })))));
    const compNumbers = (c_1, p_2) => {
        const diff = (c_1 - p_2) | 0;
        if (diff === 0) {
            return "Lik forrige periode";
        }
        else {
            return `${diff} ${(diff > 0) ? "over" : "under"} forrige periode`;
        }
    };
    const compValue = (c_2, p_3) => {
        const diff_1 = (c_2 - p_3) | 0;
        if (diff_1 === 0) {
            return "Lik forrige periode";
        }
        else {
            const comp_1 = (diff_1 > 0) ? "mer" : "mindre";
            return `${toShortAmountL(toInt64(op_Multiply(toInt64(fromInt32(diff_1)), 1000n)))} ${comp_1} enn forrige periode`;
        }
    };
    return section(undefined, [createElement("div", createObj(ofArray([["style", {
        display: "flex",
        alignItems: "center",
        gap: ((15 + "px ") + 15) + "px",
    }], (elems_4 = [sectionTitle("Oppsummering innsidehandel"), periodIndicator("14 dager")], ["children", Interop_reactApi.Children.toArray(Array.from(elems_4))])]))), createElement("div", createObj(ofArray([["style", {
        display: "flex",
        flexDirection: "column",
        gap: (((2 + "vh") + " ") + 0) + "px",
    }], (elems_7 = toList(delay(() => {
        let elems_5;
        if (x != null) {
            const y_2 = x;
            return append(singleton(createElement("div", createObj(ofArray([["style", {
                display: "flex",
                flexWrap: "wrap",
                justifyContent: "space-around",
                alignItems: "baseline",
                gap: ((30 + "px ") + 10) + "px",
            }], (elems_5 = [highlighted("Innsidehandler", int32ToString(y_2.NumTrades), y_2.NumTrades, y_2.PrevNumTrades, compNumbers(y_2.NumTrades, y_2.PrevNumTrades)), highlighted("Innsidekjøp", int32ToString(y_2.NumBought), y_2.NumBought, y_2.PrevNumBought, compNumbers(y_2.NumBought, y_2.PrevNumBought)), highlighted("Innsidesalg", int32ToString(y_2.NumSold), y_2.NumSold, y_2.PrevNumSold, compNumbers(y_2.NumSold, y_2.PrevNumSold)), highlighted("Estimert verdi", `${toShortAmountL(toInt64(op_Multiply(toInt64(fromInt32(y_2.TotalEstimatedValue)), 1000n)))} NOK`, y_2.TotalEstimatedValue, y_2.PrevTotalEstimatedValue, compValue(y_2.TotalEstimatedValue, y_2.PrevTotalEstimatedValue)), highlighted("Estimert verdi av kjøp", `${toShortAmountL(toInt64(op_Multiply(toInt64(fromInt32(y_2.TotalEstimatedValueBought)), 1000n)))} NOK`, y_2.TotalEstimatedValueBought, y_2.PrevTotalEstimatedValueBought, compValue(y_2.TotalEstimatedValueBought, y_2.PrevTotalEstimatedValueBought)), highlighted("Estimert verdi av salg", `${toShortAmountL(toInt64(op_Multiply(toInt64(fromInt32(y_2.TotalEstimateValueSold)), 1000n)))} NOK`, y_2.TotalEstimateValueSold, y_2.PrevTotalEstimatedValueSold, compValue(y_2.TotalEstimateValueSold, y_2.PrevTotalEstimatedValueSold))], ["children", Interop_reactApi.Children.toArray(Array.from(elems_5))])])))), delay(() => {
                let elems_6;
                return singleton(createElement("div", createObj(ofArray([["style", {
                    display: "flex",
                    flexWrap: "wrap",
                    justifyContent: "space-around",
                    alignItems: "baseline",
                    gap: ((30 + "px ") + 10) + "px",
                }], (elems_6 = [highlightedAsset("Flest handler", y_2.MostTrades, (n) => (`${n} kjøp og salg`)), highlightedAsset("Størst handelsverdi", y_2.HighestTradeValue, (n_1) => (`${toShortAmountL(toInt64(op_Multiply(toInt64(fromInt32(n_1)), 1000n)))} NOK kjøpt og solgt`)), highlightedAsset("Flest kjøp", y_2.MostBoughtByNum, (n_2) => (`${n_2} innsidekjøp`)), highlightedAsset("Størst kjøpeverdi", y_2.MostBoughtByValue, (n_3) => (`${toShortAmountL(toInt64(op_Multiply(toInt64(fromInt32(n_3)), 1000n)))} NOK kjøpt`)), highlightedAsset("Flest salg", y_2.MostSoldByNum, (n_4) => (`${n_4} innsidesalg`)), highlightedAsset("Størst salgsverdi", y_2.MostSoldByValue, (n_5) => (`${toShortAmountL(toInt64(op_Multiply(toInt64(fromInt32(n_5)), 1000n)))}NOK solgt`))], ["children", Interop_reactApi.Children.toArray(Array.from(elems_6))])]))));
            }));
        }
        else {
            return singleton(LoadingIndicator_skeleton(ofArray([["minWidth", 250], ["width", 100 + "%"], ["minHeight", 210]])));
        }
    })), ["children", Interop_reactApi.Children.toArray(Array.from(elems_7))])])))]);
}

function dateBoughtColumn(x) {
    let elems;
    return createElement("div", createObj(ofArray([["style", {
        display: "flex",
        flexDirection: "column",
        userSelect: "none",
    }], (elems = [createElement("span", {
        style: {
            fontWeight: "bold",
        },
        children: `${utcDateToLocalDate(x)}`,
    }), createElement("span", {
        style: {
            fontSize: Dimensions_TextExtraSmall,
        },
        children: "Dato meldt",
    })], ["children", Interop_reactApi.Children.toArray(Array.from(elems))])])));
}

function drawLargestTransactions(x) {
    return section(470, toList(delay(() => {
        let elems;
        return append(singleton(createElement("div", createObj(ofArray([["style", {
            display: "flex",
            alignItems: "center",
            gap: ((15 + "px ") + 15) + "px",
        }], (elems = [sectionTitle("Største handler etter estimert verdi"), periodIndicator("14 dager")], ["children", Interop_reactApi.Children.toArray(Array.from(elems))])])))), delay(() => {
            let matchValue, children_4, children_2;
            return append((matchValue = x, (matchValue != null) ? singleton((children_4 = singleton_1((children_2 = map_1((z_1) => {
                let elems_1;
                const children = ofArray([td$0027([assetNameColumn(z_1.Ticker, z_1.Market, z_1.Name)]), td$0027([createElement("div", createObj(ofArray([["style", {
                    display: "flex",
                    flexDirection: "column",
                    userSelect: "none",
                }], (elems_1 = [createElement("span", {
                    style: {
                        fontWeight: "bold",
                    },
                    children: toShortAmountL(toInt64(op_Multiply(toInt64(fromInt32(z_1.EstValueKs)), 1000n))),
                }), createElement("span", {
                    style: {
                        fontSize: Dimensions_TextExtraSmall,
                    },
                    children: "NOK",
                })], ["children", Interop_reactApi.Children.toArray(Array.from(elems_1))])])))]), td$0027([dateBoughtColumn(z_1.Date)])]);
                return createElement("tr", {
                    children: Interop_reactApi.Children.toArray(Array.from(children)),
                });
            }, sortByDescending((z) => {
                if (z.EstValueKs < 0) {
                    return (z.EstValueKs * -1) | 0;
                }
                else {
                    return z.EstValueKs | 0;
                }
            }, matchValue, {
                Compare: comparePrimitives,
            })), createElement("tbody", {
                children: Interop_reactApi.Children.toArray(Array.from(children_2)),
            }))), createElement("table", {
                children: Interop_reactApi.Children.toArray(Array.from(children_4)),
            }))) : append(singleton(LoadingIndicator_assetListEntryLoading()), delay(() => append(singleton(LoadingIndicator_assetListEntryLoading()), delay(() => append(singleton(LoadingIndicator_assetListEntryLoading()), delay(() => singleton(LoadingIndicator_assetListEntryLoading())))))))), delay(() => singleton(aiInfo())));
        }));
    })));
}

function drawLargestPercentages(x) {
    return section(470, toList(delay(() => {
        let elems;
        return append(singleton(createElement("div", createObj(ofArray([["style", {
            display: "flex",
            alignItems: "center",
            gap: ((15 + "px ") + 15) + "px",
        }], (elems = [sectionTitle("Største handler andel av utestående aksjer"), periodIndicator("14 dager")], ["children", Interop_reactApi.Children.toArray(Array.from(elems))])])))), delay(() => {
            let matchValue, children_4, children_2;
            return append((matchValue = x, (matchValue != null) ? singleton((children_4 = singleton_1((children_2 = map_1((z_1) => {
                let elems_1;
                const children = ofArray([td$0027([assetNameColumn(z_1.Ticker, z_1.Market, z_1.Name)]), td$0027([createElement("div", createObj(ofArray([["style", {
                    display: "flex",
                    flexDirection: "column",
                    userSelect: "none",
                }], (elems_1 = [createElement("span", {
                    style: {
                        fontWeight: "bold",
                    },
                    children: shortPercentageToString(z_1.Percentage),
                }), createElement("span", {
                    style: {
                        fontSize: Dimensions_TextExtraSmall,
                    },
                    children: "Av utestående",
                })], ["children", Interop_reactApi.Children.toArray(Array.from(elems_1))])])))]), td$0027([dateBoughtColumn(z_1.Date)])]);
                return createElement("tr", {
                    children: Interop_reactApi.Children.toArray(Array.from(children)),
                });
            }, sortByDescending((z) => {
                if (z.Percentage < 0) {
                    return (z.Percentage * -1) | 0;
                }
                else {
                    return z.Percentage | 0;
                }
            }, matchValue, {
                Compare: comparePrimitives,
            })), createElement("tbody", {
                children: Interop_reactApi.Children.toArray(Array.from(children_2)),
            }))), createElement("table", {
                children: Interop_reactApi.Children.toArray(Array.from(children_4)),
            }))) : append(singleton(LoadingIndicator_assetListEntryLoading()), delay(() => append(singleton(LoadingIndicator_assetListEntryLoading()), delay(() => append(singleton(LoadingIndicator_assetListEntryLoading()), delay(() => singleton(LoadingIndicator_assetListEntryLoading())))))))), delay(() => singleton(aiInfo())));
        }));
    })));
}

export function InsiderTrades() {
    let elems_7, elems_4, elems_3, elems_1, elems, elems_2, elems_6, elems_5;
    const patternInput = useReact_useReducer_2B9E6EA0(update, init());
    const model_1 = patternInput[0];
    const dispatch = patternInput[1];
    useReact_useEffectOnce_3A5B6456(() => {
        fetchSummary(dispatch);
        fetchTransactions(dispatch);
        fetchPercentages(dispatch);
    });
    return createElement("div", createObj(ofArray([["className", "default-gap"], ["style", {
        display: "flex",
        flexDirection: "column",
        padding: 1 + "vw",
    }], (elems_7 = [drawSummary(model_1.Summary), createElement("div", createObj(ofArray([["className", "default-gap"], ["style", {
        display: "flex",
        flexWrap: "wrap",
    }], (elems_4 = [drawLargestTransactions(model_1.LargestTransactions), drawLargestPercentages(model_1.LargestPercentages), createElement("div", createObj(ofArray([["key", "latest-trades-holder"], ["style", {
        maxWidth: 650,
    }], (elems_3 = [section(undefined, [createElement("div", createObj(ofArray([["style", {
        display: "flex",
        justifyContent: "space-between",
        alignItems: "center",
    }], (elems_1 = [createElement("div", createObj(ofArray([["style", {
        borderBottom: (((3 + "px ") + "solid") + " ") + "var(--bg-normal)",
        flexGrow: 1,
        maxWidth: 400,
    }], (elems = [createElement("span", {
        style: {
            fontWeight: 500,
            margin: 0,
            userSelect: "none",
        },
        children: "Siste registrerte handler",
    })], ["children", Interop_reactApi.Children.toArray(Array.from(elems))])]))), createElement("span", {
        style: {
            color: "var(--bg-normal)",
        },
        className: "fas fa-broadcast-tower",
    })], ["children", Interop_reactApi.Children.toArray(Array.from(elems_1))])])))]), createElement("div", createObj(ofArray([["style", {
        maxHeight: 600,
        overflowY: "auto",
    }], (elems_2 = [createElement(PostFeed, {
        target: new Target(5, []),
        includeAds: false,
    })], ["children", Interop_reactApi.Children.toArray(Array.from(elems_2))])])))], ["children", Interop_reactApi.Children.toArray(Array.from(elems_3))])])))], ["children", Interop_reactApi.Children.toArray(Array.from(elems_4))])]))), createElement("div", createObj(ofArray([["style", {
        display: "flex",
        justifyContent: "center",
        width: 100 + "%",
    }], (elems_6 = [createElement("div", createObj(ofArray([["style", {
        width: 100 + "%",
        maxWidth: 1200,
    }], (elems_5 = [createElement(InsiderTradeOverview, null)], ["children", Interop_reactApi.Children.toArray(Array.from(elems_5))])])))], ["children", Interop_reactApi.Children.toArray(Array.from(elems_6))])])))], ["children", Interop_reactApi.Children.toArray(Array.from(elems_7))])])));
}

