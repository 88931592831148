import { Union, Record } from "./fable_modules/fable-library-js.4.17.0/Types.js";
import { obj_type, union_type, array_type, bool_type, anonRecord_type, option_type, record_type, string_type, int32_type } from "./fable_modules/fable-library-js.4.17.0/Reflection.js";
import { fromString as fromString_1, string, int, object } from "./fable_modules/Thoth.Json.6.0.0/Decode.fs.js";
import { createObj, uncurry2 } from "./fable_modules/fable-library-js.4.17.0/Util.js";
import { Notifications_Notification_$reflection, Notifications_EntityInfo, Notifications_EntityInfo_$reflection } from "./Shared/ApiDataTypes.js";
import { choose, append, map } from "./fable_modules/fable-library-js.4.17.0/Array.js";
import { PromiseBuilder__Delay_62FBFDE1, PromiseBuilder__Run_212F1D4B } from "./fable_modules/Fable.Promise.2.0.0/Promise.fs.js";
import { promise } from "./fable_modules/Fable.Promise.2.0.0/PromiseImpl.fs.js";
import { Auto_generateBoxedEncoderCached_437914C6, Auto_generateBoxedEncoder_437914C6 } from "./fable_modules/Thoth.Json.6.0.0/Encode.fs.js";
import { unwrap, map as map_1, defaultArg, some } from "./fable_modules/fable-library-js.4.17.0/Option.js";
import { PromiseBuilder__Delay_62FBFDE1 as PromiseBuilder__Delay_62FBFDE1_1, PromiseBuilder__Run_212F1D4B as PromiseBuilder__Run_212F1D4B_1 } from "./fable_modules/Fable.Promise.2.0.0/Promise.fs.js";
import { promise as promise_1 } from "./fable_modules/Fable.Promise.2.0.0/PromiseImpl.fs.js";
import { FetchError } from "./fable_modules/Thoth.Fetch.3.0.1/Fetch.fs.js";
import { FSharpResult$2 } from "./fable_modules/fable-library-js.4.17.0/Result.js";
import { Helper_fetch, Helper_withContentTypeJson, Helper_withProperties } from "./fable_modules/Thoth.Fetch.3.0.1/Fetch.fs.js";
import { Types_RequestProperties } from "./fable_modules/Fable.Fetch.2.1.0/Fetch.fs.js";
import { keyValueList } from "./fable_modules/fable-library-js.4.17.0/MapUtil.js";
import { cons, ofArray, empty } from "./fable_modules/fable-library-js.4.17.0/List.js";
import { toString } from "./fable_modules/Thoth.Json.6.0.0/Encode.fs.js";
import { Auto_generateBoxedDecoder_Z6670B51, Auto_generateBoxedDecoderCached_Z6670B51 } from "./fable_modules/Thoth.Json.6.0.0/Decode.fs.js";
import { fromString } from "./fable_modules/Thoth.Json.6.0.0/Decode.fs.js";
import { MuiHelpers_createElement } from "./Feliz.MaterialUI/Mui.js";
import { Avatar } from "@mui/material";
import { createElement } from "react";
import React from "react";
import * as react from "react";
import { empty as empty_1, singleton, append as append_1, delay, toList } from "./fable_modules/fable-library-js.4.17.0/Seq.js";
import { Dimensions_DefaultPadding, DefaultBorder, Dimensions_DefaultGap } from "./Design.js";
import { LoadingIndicator_loadingIndicator, Alert_snackError, Image_userCircle } from "./ViewHelpers.js";
import { Interop_reactApi } from "./fable_modules/Feliz.2.7.0/Interop.fs.js";
import { defaultOf } from "./fable_modules/fable-library-js.4.17.0/Util.js";
import { utcStringToLocalTimeSpan } from "./Utils.js";
import { useReact_useEffectOnce_3A5B6456, useReact_useReducer_2B9E6EA0 } from "./fable_modules/Feliz.2.7.0/React.fs.js";

class PostInfo extends Record {
    constructor(Id, Type) {
        super();
        this.Id = (Id | 0);
        this.Type = Type;
    }
}

function PostInfo_$reflection() {
    return record_type("Investfora.Notifications.PostInfo", [], PostInfo, () => [["Id", int32_type], ["Type", string_type]]);
}

function PostInfo_get_Decode() {
    return (path_1) => ((v) => object((x) => {
        let objectArg, objectArg_1;
        return new PostInfo((objectArg = x.Required, objectArg.Field("Id", uncurry2(int))), (objectArg_1 = x.Required, objectArg_1.Field("Type", string)));
    }, path_1, v));
}

class NotificationContent extends Record {
    constructor(Type, Desc, GroupInfo, UserInfo, Link, AssetInfo, PostInfo) {
        super();
        this.Type = (Type | 0);
        this.Desc = Desc;
        this.GroupInfo = GroupInfo;
        this.UserInfo = UserInfo;
        this.Link = Link;
        this.AssetInfo = AssetInfo;
        this.PostInfo = PostInfo;
    }
}

function NotificationContent_$reflection() {
    return record_type("Investfora.Notifications.NotificationContent", [], NotificationContent, () => [["Type", int32_type], ["Desc", string_type], ["GroupInfo", option_type(Notifications_EntityInfo_$reflection())], ["UserInfo", option_type(Notifications_EntityInfo_$reflection())], ["Link", option_type(string_type)], ["AssetInfo", option_type(anonRecord_type(["Market", string_type], ["Name", string_type], ["Ticker", string_type]))], ["PostInfo", option_type(PostInfo_$reflection())]]);
}

function NotificationContent_get_Decode() {
    const EntityInfoDecode = (path_1, v) => object((x) => {
        let objectArg_1;
        let Id;
        const objectArg = x.Required;
        Id = objectArg.Field("Id", uncurry2(int));
        return new Notifications_EntityInfo((objectArg_1 = x.Required, objectArg_1.Field("Name", string)), Id);
    }, path_1, v);
    return (path_4) => ((v_1) => object((x_1) => {
        let objectArg_2, objectArg_3, objectArg_4, objectArg_5, objectArg_6, arg_15, objectArg_7;
        return new NotificationContent((objectArg_2 = x_1.Required, objectArg_2.Field("Type", uncurry2(int))), (objectArg_3 = x_1.Required, objectArg_3.Field("Desc", string)), (objectArg_4 = x_1.Optional, objectArg_4.Field("GroupInfo", EntityInfoDecode)), (objectArg_5 = x_1.Optional, objectArg_5.Field("UserInfo", EntityInfoDecode)), (objectArg_6 = x_1.Optional, objectArg_6.Field("Link", string)), undefined, (arg_15 = PostInfo_get_Decode(), (objectArg_7 = x_1.Optional, objectArg_7.Field("PostInfo", uncurry2(arg_15)))));
    }, path_4, v_1));
}

class Entry extends Record {
    constructor(Content, Id, Notified, Read) {
        super();
        this.Content = Content;
        this.Id = (Id | 0);
        this.Notified = Notified;
        this.Read = Read;
    }
}

function Entry_$reflection() {
    return record_type("Investfora.Notifications.Entry", [], Entry, () => [["Content", NotificationContent_$reflection()], ["Id", int32_type], ["Notified", string_type], ["Read", bool_type]]);
}

class Model extends Record {
    constructor(Notifications, ErrorMsg) {
        super();
        this.Notifications = Notifications;
        this.ErrorMsg = ErrorMsg;
    }
}

function Model_$reflection() {
    return record_type("Investfora.Notifications.Model", [], Model, () => [["Notifications", option_type(array_type(Entry_$reflection()))], ["ErrorMsg", option_type(string_type)]]);
}

class Message extends Union {
    constructor(tag, fields) {
        super();
        this.tag = tag;
        this.fields = fields;
    }
    cases() {
        return ["AddNotifications", "SetRead", "SetError", "DismissError"];
    }
}

function Message_$reflection() {
    return union_type("Investfora.Notifications.Message", [], Message, () => [[["Item", array_type(Entry_$reflection())]], [["Item", array_type(int32_type)]], [["Item", string_type]], []]);
}

function init() {
    return new Model(undefined, undefined);
}

function update(model, msg) {
    let matchValue_1, matchValue;
    switch (msg.tag) {
        case 1:
            return new Model((matchValue_1 = model.Notifications, (matchValue_1 != null) ? map((n) => {
                if (msg.fields[0].some((y_1) => (n.Id === y_1))) {
                    return new Entry(n.Content, n.Id, n.Notified, true);
                }
                else {
                    return n;
                }
            }, matchValue_1) : undefined), model.ErrorMsg);
        case 2:
            return new Model(model.Notifications, msg.fields[0]);
        case 3:
            return new Model(model.Notifications, undefined);
        default: {
            const x = msg.fields[0];
            return new Model((matchValue = model.Notifications, (matchValue != null) ? append(matchValue, x) : x), model.ErrorMsg);
        }
    }
}

function dismissNotifications(x, dispatch) {
    const pr_1 = PromiseBuilder__Run_212F1D4B(promise, PromiseBuilder__Delay_62FBFDE1(promise, () => {
        let data_1, caseStrategy_2, extra_2;
        return ((data_1 = some(Auto_generateBoxedEncoder_437914C6(array_type(int32_type), undefined, undefined, undefined)(x)), (caseStrategy_2 = undefined, (extra_2 = undefined, (() => {
            let properties_2;
            try {
                const properties_3 = Helper_withProperties(undefined, (properties_2 = ofArray([new Types_RequestProperties(0, ["POST"]), new Types_RequestProperties(1, [keyValueList(Helper_withContentTypeJson(data_1, empty()), 0)])]), defaultArg(map_1((data_1_1) => cons(new Types_RequestProperties(2, [toString(0, Auto_generateBoxedEncoderCached_437914C6(obj_type, caseStrategy_2, extra_2)(data_1_1))]), properties_2), data_1), properties_2)));
                const pr = PromiseBuilder__Run_212F1D4B_1(promise_1, PromiseBuilder__Delay_62FBFDE1_1(promise_1, () => (Helper_fetch("/api/notifications", properties_3).then((_arg) => {
                    let response_1, decoder_1_1;
                    return ((response_1 = _arg, (decoder_1_1 = defaultArg(undefined, Auto_generateBoxedDecoderCached_Z6670B51(obj_type, unwrap(caseStrategy_2), unwrap(extra_2))), PromiseBuilder__Run_212F1D4B_1(promise_1, PromiseBuilder__Delay_62FBFDE1_1(promise_1, () => (((response_1.ok) ? PromiseBuilder__Run_212F1D4B_1(promise_1, PromiseBuilder__Delay_62FBFDE1_1(promise_1, () => (response_1.text().then((_arg_1) => {
                        let matchValue;
                        return Promise.resolve((matchValue = fromString(uncurry2(decoder_1_1), _arg_1), (matchValue.tag === 1) ? (new FSharpResult$2(1, [new FetchError(1, [matchValue.fields[0]])])) : (new FSharpResult$2(0, [matchValue.fields[0]]))));
                    })))) : (Promise.resolve(new FSharpResult$2(1, [new FetchError(2, [response_1])])))).then((_arg_1_1) => (Promise.resolve(_arg_1_1)))))))));
                }))));
                return pr.then(void 0, ((arg) => (new FSharpResult$2(1, [new FetchError(3, [arg])]))));
            }
            catch (exn) {
                return PromiseBuilder__Run_212F1D4B_1(promise_1, PromiseBuilder__Delay_62FBFDE1_1(promise_1, () => (Promise.resolve(new FSharpResult$2(1, [new FetchError(0, [exn])])))));
            }
        })())))).then((_arg_2) => {
            if (_arg_2.tag === 0) {
                dispatch(new Message(1, [x]));
                return Promise.resolve();
            }
            else {
                return Promise.resolve();
            }
        });
    }));
    pr_1.then();
}

function fetchNotifications(dispatch) {
    const pr_1 = PromiseBuilder__Run_212F1D4B(promise, PromiseBuilder__Delay_62FBFDE1(promise, () => {
        let decoder, data_1, caseStrategy_2, extra_2;
        return ((decoder = Auto_generateBoxedDecoder_Z6670B51(array_type(Notifications_Notification_$reflection()), undefined, undefined), (data_1 = undefined, (caseStrategy_2 = undefined, (extra_2 = undefined, (() => {
            let properties_2;
            try {
                const properties_3 = Helper_withProperties(empty(), (properties_2 = ofArray([new Types_RequestProperties(0, ["GET"]), new Types_RequestProperties(1, [keyValueList(Helper_withContentTypeJson(data_1, empty()), 0)])]), defaultArg(map_1((data_1_1) => cons(new Types_RequestProperties(2, [toString(0, Auto_generateBoxedEncoderCached_437914C6(obj_type, caseStrategy_2, extra_2)(data_1_1))]), properties_2), data_1), properties_2)));
                const pr = PromiseBuilder__Run_212F1D4B_1(promise_1, PromiseBuilder__Delay_62FBFDE1_1(promise_1, () => (Helper_fetch("/api/notifications", properties_3).then((_arg) => {
                    let response_1, decoder_1_1;
                    return ((response_1 = _arg, (decoder_1_1 = defaultArg(decoder, Auto_generateBoxedDecoderCached_Z6670B51(array_type(Notifications_Notification_$reflection()), unwrap(caseStrategy_2), unwrap(extra_2))), PromiseBuilder__Run_212F1D4B_1(promise_1, PromiseBuilder__Delay_62FBFDE1_1(promise_1, () => (((response_1.ok) ? PromiseBuilder__Run_212F1D4B_1(promise_1, PromiseBuilder__Delay_62FBFDE1_1(promise_1, () => (response_1.text().then((_arg_1) => {
                        let matchValue;
                        return Promise.resolve((matchValue = fromString(uncurry2(decoder_1_1), _arg_1), (matchValue.tag === 1) ? (new FSharpResult$2(1, [new FetchError(1, [matchValue.fields[0]])])) : (new FSharpResult$2(0, [matchValue.fields[0]]))));
                    })))) : (Promise.resolve(new FSharpResult$2(1, [new FetchError(2, [response_1])])))).then((_arg_1_1) => (Promise.resolve(_arg_1_1)))))))));
                }))));
                return pr.then(void 0, ((arg) => (new FSharpResult$2(1, [new FetchError(3, [arg])]))));
            }
            catch (exn) {
                return PromiseBuilder__Run_212F1D4B_1(promise_1, PromiseBuilder__Delay_62FBFDE1_1(promise_1, () => (Promise.resolve(new FSharpResult$2(1, [new FetchError(0, [exn])])))));
            }
        })()))))).then((_arg_2) => {
            const res = _arg_2;
            if (res.tag === 0) {
                const x_1 = res.fields[0];
                return PromiseBuilder__Delay_62FBFDE1(promise, () => {
                    dispatch(new Message(0, [choose((y) => {
                        const matchValue_1 = fromString_1(uncurry2(NotificationContent_get_Decode()), y.Blob);
                        if (matchValue_1.tag === 0) {
                            return new Entry(matchValue_1.fields[0], y.Id, y.Notified, y.Read);
                        }
                        else {
                            return undefined;
                        }
                    }, x_1)]));
                    return (x_1.length > 0) ? ((new Promise(resolve => setTimeout(resolve, 2000))).then(() => {
                        const y_1 = map((z_2) => z_2.Id, x_1.filter((z_1) => !z_1.Read), Int32Array);
                        if (y_1.length > 0) {
                            dismissNotifications(y_1, dispatch);
                            return Promise.resolve();
                        }
                        else {
                            return Promise.resolve();
                        }
                    })) : (Promise.resolve());
                }).catch((_arg_4) => {
                    dispatch(new Message(2, ["Kunne ikke laste varsler. Vennligst prøv igjen senere."]));
                    return Promise.resolve();
                });
            }
            else {
                dispatch(new Message(2, ["Kunne ikke laste varsler. Vennligst prøv igjen senere."]));
                return Promise.resolve();
            }
        });
    }));
    pr_1.then();
}

function drawNotification(x) {
    let elems_4, elems_1, elems_3;
    const fallBackAvatar = () => MuiHelpers_createElement(Avatar, [["width", 40], ["height", 40], ["style", {
        width: 40,
        height: 40,
        "box-shadow": "0 2px 4px rgba(55,55,55,0.1),0 4px 8px rgba(55,55,55,0.1)",
    }], ["alt", "Varsel ikon"], ["children", createElement("i", {
        className: "fas fa-bell",
    })]]);
    return createElement("div", createObj(ofArray([["key", x.Id], ["style", createObj(toList(delay(() => append_1(singleton(["display", "flex"]), delay(() => append_1(singleton(["marginBottom", 5]), delay(() => append_1(singleton(["width", 100 + "%"]), delay(() => append_1(singleton(["maxWidth", 450]), delay(() => append_1(singleton(["padding", Dimensions_DefaultGap]), delay(() => append_1(singleton(["transition", "background-color 1s linear"]), delay(() => append_1(singleton(["borderRadius", 5]), delay(() => append_1(singleton(DefaultBorder), delay(() => (!x.Read ? append_1(singleton(["backgroundColor", "var(--bg-light)"]), delay(() => singleton(["color", "#FFFFFF"]))) : append_1(singleton(["backgroundColor", "#FFFFFF"]), delay(() => singleton(["color", "#363636"])))))))))))))))))))))))], (elems_4 = [createElement("div", createObj(ofArray([["style", {
        marginRight: 15,
    }], (elems_1 = toList(delay(() => {
        let elems;
        const matchValue = x.Content.Type | 0;
        switch (matchValue) {
            case 1:
            case 2:
            case 3: {
                const matchValue_1 = x.Content.UserInfo;
                if (matchValue_1 != null) {
                    const ui = matchValue_1;
                    return singleton(createElement("a", createObj(ofArray([["href", `/user/${ui.Name}`], (elems = [Image_userCircle(ui.Id, 40, false, undefined)], ["children", Interop_reactApi.Children.toArray(Array.from(elems))])]))));
                }
                else {
                    return singleton(fallBackAvatar());
                }
            }
            case 4:
            case 5:
            case 6:
                return singleton(MuiHelpers_createElement(Avatar, [["width", 40], ["height", 40], ["style", {
                    width: 40,
                    height: 40,
                    "box-shadow": "0 2px 4px rgba(55,55,55,0.1),0 4px 8px rgba(55,55,55,0.1)",
                }], ["alt", "Like ikon"], ["children", createElement("i", {
                    className: "fas fa-thumbs-up",
                })]]));
            case 7:
            case 8:
            case 9:
            case 16:
            case 17:
            case 18:
            case 19:
                return singleton(defaultOf());
            default:
                return singleton(fallBackAvatar());
        }
    })), ["children", Interop_reactApi.Children.toArray(Array.from(elems_1))])]))), createElement("div", createObj(ofArray([["style", {
        display: "flex",
        flexDirection: "column",
        justifyContent: "space-between",
    }], (elems_3 = [createElement("a", createObj(toList(delay(() => {
        let matchValue_2;
        return append_1((matchValue_2 = x.Content.Link, (matchValue_2 != null) ? singleton(["href", matchValue_2]) : (empty_1())), delay(() => {
            let elems_2;
            return singleton((elems_2 = [createElement("span", {
                style: createObj(toList(delay(() => append_1(x.Read ? singleton(["color", "#363636"]) : singleton(["color", "#FFFFFF"]), delay(() => singleton(["userSelect", "none"])))))),
                children: x.Content.Desc,
            })], ["children", Interop_reactApi.Children.toArray(Array.from(elems_2))]));
        }));
    })))), createElement("span", {
        style: {
            fontSize: 0.7 + "rem",
            userSelect: "none",
        },
        children: utcStringToLocalTimeSpan(x.Notified),
    })], ["children", Interop_reactApi.Children.toArray(Array.from(elems_3))])])))], ["children", Interop_reactApi.Children.toArray(Array.from(elems_4))])])));
}

function view(model, dispatch) {
    let elems_2;
    return createElement("div", createObj(ofArray([["style", {
        display: "flex",
        flexDirection: "column",
        padding: Dimensions_DefaultPadding,
        alignItems: "center",
    }], (elems_2 = toList(delay(() => append_1(singleton(Alert_snackError(model.ErrorMsg, () => {
        dispatch(new Message(3, []));
    })), delay(() => append_1(singleton(createElement("span", {
        className: "title is-2",
        style: {
            textAlign: "center",
            marginBottom: Dimensions_DefaultPadding,
        },
        children: "Varsler",
    })), delay(() => {
        let xs_2, elems;
        const matchValue = model.Notifications;
        if (matchValue != null) {
            const x = matchValue;
            return append_1(singleton((xs_2 = map(drawNotification, x), react.createElement(react.Fragment, {}, ...xs_2))), delay(() => {
                let elems_1;
                return (x.length === 0) ? singleton(createElement("div", createObj(ofArray([["style", {
                    display: "flex",
                    justifyContent: "center",
                    margin: 10,
                }], (elems_1 = [createElement("span", {
                    children: "Ingen varsler",
                })], ["children", Interop_reactApi.Children.toArray(Array.from(elems_1))])])))) : empty_1();
            }));
        }
        else {
            return singleton(createElement("div", createObj(ofArray([["style", {
                display: "flex",
                justifyContent: "center",
                margin: 10,
                alignItems: "center",
            }], (elems = [LoadingIndicator_loadingIndicator()], ["children", Interop_reactApi.Children.toArray(Array.from(elems))])]))));
        }
    })))))), ["children", Interop_reactApi.Children.toArray(Array.from(elems_2))])])));
}

export function Notifications() {
    const patternInput = useReact_useReducer_2B9E6EA0(update, init());
    const dispatch = patternInput[1];
    useReact_useEffectOnce_3A5B6456(() => {
        fetchNotifications(dispatch);
    });
    return view(patternInput[0], dispatch);
}

