import { createElement } from "react";
import React from "react";
import * as react from "react";
import { int32ToString, equals, createObj } from "./fable_modules/fable-library-js.4.17.0/Util.js";
import { ofArray } from "./fable_modules/fable-library-js.4.17.0/List.js";
import { Interop_reactApi } from "./fable_modules/Feliz.2.7.0/Interop.fs.js";
import { defaultOf } from "./fable_modules/fable-library-js.4.17.0/Util.js";
import { React_createDisposable_3A5B6456, useReact_useEffect_Z5ECA432F, useReact_useRef_1505, useReact_useEffectOnce_3A5B6456 } from "./fable_modules/Feliz.2.7.0/React.fs.js";
import { PromiseBuilder__Delay_62FBFDE1, PromiseBuilder__Run_212F1D4B } from "./fable_modules/Fable.Promise.2.0.0/Promise.fs.js";
import { promise } from "./fable_modules/Fable.Promise.2.0.0/PromiseImpl.fs.js";
import { format, printf, toText } from "./fable_modules/fable-library-js.4.17.0/String.js";
import { MuiHelpers_createElement } from "./Feliz.MaterialUI/Mui.js";
import { Avatar, Tooltip } from "@mui/material";
import { empty, singleton, append, delay, toList } from "./fable_modules/fable-library-js.4.17.0/Seq.js";

export function LoadingIndicator_skeleton(styles) {
    return createElement("div", {
        className: "skeleton",
        style: createObj(styles),
    });
}

export function LoadingIndicator_postLoadingIndicator() {
    return LoadingIndicator_skeleton(ofArray([["height", 248], ["width", 100 + "%"]]));
}

export function LoadingIndicator_loadingIndicatorSmall() {
    return createElement("div", {
        className: "loading",
        style: {
            width: 20,
            height: 20,
        },
    });
}

export function LoadingIndicator_loadingIndicator() {
    return createElement("div", {
        className: "loading",
        style: {
            width: 30,
            height: 30,
        },
    });
}

export function LoadingIndicator_assetListEntryLoading() {
    let elems;
    return createElement("div", createObj(ofArray([["className", "preview-listing-holder"], (elems = [LoadingIndicator_skeleton(ofArray([["height", "inherit"], ["width", 100 + "%"]]))], ["children", Interop_reactApi.Children.toArray(Array.from(elems))])])));
}

export function Alert_alert(msg, onClose) {
    let elems;
    return createElement("div", createObj(ofArray([["className", "alert-error"], ["onClick", (_arg) => {
        onClose();
    }], (elems = [createElement("i", {
        className: "fas fa-exclamation-triangle",
        style: {
            marginRight: 5,
        },
    }), createElement("span", {
        children: [msg],
    })], ["children", Interop_reactApi.Children.toArray(Array.from(elems))])])));
}

export function Alert_errorAlert(errMsg, dismiss) {
    if (errMsg != null) {
        return Alert_alert(errMsg, dismiss);
    }
    else {
        return defaultOf();
    }
}

export function Alert_SnackError(alert_SnackErrorInputProps) {
    let elems;
    const dismiss = alert_SnackErrorInputProps.dismiss;
    const errMsg = alert_SnackErrorInputProps.errMsg;
    useReact_useEffectOnce_3A5B6456(() => {
        const pr = PromiseBuilder__Run_212F1D4B(promise, PromiseBuilder__Delay_62FBFDE1(promise, () => ((new Promise(resolve => setTimeout(resolve, 10000))).then(() => {
            dismiss();
            return Promise.resolve();
        }))));
        pr.then();
    });
    return createElement("div", createObj(ofArray([["className", "snackbar"], ["onClick", (_arg_2) => {
        dismiss();
    }], (elems = [Alert_alert(errMsg, dismiss)], ["children", Interop_reactApi.Children.toArray(Array.from(elems))])])));
}

export function Alert_snackError(errMsg, dismiss) {
    if (errMsg != null) {
        const errMsg_1 = errMsg;
        return createElement(Alert_SnackError, {
            errMsg: errMsg_1,
            dismiss: dismiss,
        });
    }
    else {
        return defaultOf();
    }
}

export function Dialog_dialogTitle(txt) {
    let elems;
    return createElement("div", createObj(ofArray([["style", {
        display: "flex",
        gap: 20 + "px ",
        alignItems: "center",
    }], (elems = [createElement("span", {
        style: {
            fontSize: 1.2 + "rem",
            fontWeight: 500,
        },
        children: txt,
    })], ["children", Interop_reactApi.Children.toArray(Array.from(elems))])])));
}

export function Dialog_dialogContent(children) {
    return createElement("div", {
        style: {
            display: "flex",
            flexDirection: "column",
            gap: ((20 + "px ") + 20) + "px",
        },
        children: Interop_reactApi.Children.toArray(Array.from(children)),
    });
}

export function Dialog_dialogActions(children) {
    return createElement("div", {
        style: {
            display: "flex",
            gap: 20 + "px ",
            alignItems: "center",
            justifyContent: "flex-end",
        },
        children: Interop_reactApi.Children.toArray(Array.from(children)),
    });
}

export function Dialog_Dialog(dialog_DialogInputProps) {
    let elems_1;
    const children = dialog_DialogInputProps.children;
    const onClose = dialog_DialogInputProps.onClose;
    const ref = useReact_useRef_1505(undefined);
    const closeEffect = (event) => {
        onClose();
    };
    useReact_useEffect_Z5ECA432F(() => {
        window.addEventListener("onclick", closeEffect);
        return React_createDisposable_3A5B6456(() => {
            window.removeEventListener("onclick", closeEffect);
        });
    });
    return createElement("div", createObj(ofArray([["ref", ref], ["className", "dialog-backdrop"], ["onClick", (event_1) => {
        if (equals(event_1.target, ref.current)) {
            window.removeEventListener("onclick", closeEffect);
            onClose();
        }
    }], (elems_1 = [createElement("div", {
        className: "dialog",
        children: Interop_reactApi.Children.toArray(Array.from(children)),
    })], ["children", Interop_reactApi.Children.toArray(Array.from(elems_1))])])));
}

export function nbText(txt) {
    return createElement("span", {
        style: {
            fontSize: 0.7 + "rem",
            fontStyle: "italic",
        },
        children: txt,
    });
}

export function limitedText(txt, maxLength) {
    if (txt.length > maxLength) {
        const sub = txt.slice(0, (maxLength - 3) + 1);
        return toText(printf("%s..."))(sub);
    }
    else {
        return txt;
    }
}

export function wrapTooltip(x, y) {
    return MuiHelpers_createElement(Tooltip, [["title", x], ["children", y]]);
}

export function handleNumber(num) {
    let x_1;
    if (num < 1000) {
        return int32ToString(num);
    }
    else if ((x_1 = (num | 0), (x_1 >= 1000) && (x_1 < 1000000))) {
        const y = num / 1000;
        const arg = format('{0:' + "0.0" + '}', y);
        return toText(printf("%sk"))(arg);
    }
    else {
        const y_1 = num / 1000000;
        const arg_1 = format('{0:' + "0.0" + '}', y_1);
        return toText(printf("%sm"))(arg_1);
    }
}

export function textCounter(num, maxNum) {
    return createElement("span", {
        style: {
            fontSize: 0.8 + "rem",
            fontStyle: "italic",
            color: (num > maxNum) ? "#cc3608" : "#bababa",
            textAlign: "left",
        },
        children: toText(printf("%i/%i"))(num)(maxNum),
    });
}

export function smallIconButton(className, onClick) {
    let elems;
    return createElement("button", createObj(ofArray([["style", {
        cursor: "pointer",
        margin: 0,
        marginRight: 4,
        padding: 0,
        border: "none",
        background: "none",
        height: 18,
        width: 18,
    }], ["onClick", onClick], (elems = [createElement("i", {
        className: className,
    })], ["children", Interop_reactApi.Children.toArray(Array.from(elems))])])));
}

export function pillButtonSmall(styles, title, onClick) {
    let elems;
    return createElement("button", createObj(ofArray([["className", "pill-button-small"], ["style", createObj(styles)], ["onClick", onClick], (elems = [createElement("span", {
        style: {
            textAlign: "center",
        },
        children: title,
    })], ["children", Interop_reactApi.Children.toArray(Array.from(elems))])])));
}

export function pillButton(styles, title, onClick) {
    return createElement("button", {
        onClick: onClick,
        className: "pill-button",
        children: title,
    });
}

export function groupButtonLeft(isActive, onClick, children) {
    return createElement("button", {
        className: isActive ? "grouped-button-left grouped-button-active" : "grouped-button-left",
        onClick: onClick,
        children: Interop_reactApi.Children.toArray(Array.from(children)),
    });
}

export function groupButtonCenter(isActive, onClick, children) {
    return createElement("button", {
        className: isActive ? "grouped-button-center grouped-button-active" : "grouped-button-center",
        onClick: onClick,
        children: Interop_reactApi.Children.toArray(Array.from(children)),
    });
}

export function groupButtonRight(isActive, onClick, children) {
    return createElement("button", {
        className: isActive ? "grouped-button-right grouped-button-active" : "grouped-button-right",
        onClick: onClick,
        children: Interop_reactApi.Children.toArray(Array.from(children)),
    });
}

export function tooltipHeader(header, tooltipTitle) {
    let elems;
    return createElement("div", createObj(ofArray([["style", {
        display: "flex",
        alignItems: "center",
    }], (elems = [createElement("span", {
        style: {
            color: "var(--text-main)",
            fontStyle: "italic",
            paddingLeft: 7,
        },
        children: header,
    }), MuiHelpers_createElement(Tooltip, [["title", tooltipTitle], ["children", createElement("span", {
        style: {
            color: "var(--text-dim)",
            marginLeft: 7,
        },
        className: "fas fa-question-circle",
    })]])], ["children", Interop_reactApi.Children.toArray(Array.from(elems))])])));
}

export function assetListHolder(children) {
    return createElement("div", {
        style: {
            marginBottom: 15,
            marginRight: 15,
            maxWidth: 300,
        },
        children: Interop_reactApi.Children.toArray(Array.from(children)),
    });
}

export function assetListEntry(ticker, name, link) {
    let elems_2, elems, elems_1;
    return createElement("div", createObj(ofArray([["className", "preview-listing-holder"], (elems_2 = [createElement("div", createObj(ofArray([["className", "preview-listing-name"], (elems = [createElement("a", {
        href: link,
        children: name,
    })], ["children", Interop_reactApi.Children.toArray(Array.from(elems))])]))), createElement("div", createObj(ofArray([["className", "preview-listing-ticker"], (elems_1 = [createElement("a", {
        href: link,
        children: ticker,
    })], ["children", Interop_reactApi.Children.toArray(Array.from(elems_1))])])))], ["children", Interop_reactApi.Children.toArray(Array.from(elems_2))])])));
}

export function Image_userCircle(userId, size, isLarge, randHash) {
    let arg_1, arg_2;
    return MuiHelpers_createElement(Avatar, [["width", size], ["height", size], ["style", {
        width: size,
        height: size,
        border: (((1 + "px ") + "solid") + " ") + "#e1e1e1",
    }], ["alt", "User image"], ["src", (arg_1 = (isLarge ? "normal" : "small"), (arg_2 = ((randHash != null) ? (`&r=${randHash}`) : ""), toText(printf("/api/user/image/%i?size=%s%s"))(userId)(arg_1)(arg_2)))]]);
}

export function Image_circledImage(size, imgUrl) {
    return MuiHelpers_createElement(Avatar, [["width", size], ["height", size], ["style", {
        width: size,
        height: size,
        border: (((1 + "px ") + "solid") + " ") + "#e1e1e1",
    }], ["alt", "Image"], ["src", imgUrl]]);
}

export function User_usernameText(x, postRemoved) {
    if (!postRemoved) {
        const xs_3 = toList(delay(() => append(singleton(createElement("a", {
            href: `/user/${x.Username}`,
            children: createElement("span", {
                style: {
                    color: "var(--bg-dark)",
                    fontWeight: 500,
                },
                children: x.Username,
            }),
        })), delay(() => (x.Official ? singleton(createElement("span", {
            style: {
                marginLeft: 5,
                color: "var(--text-main)",
                fontSize: 0.8 + "rem",
            },
            className: "fas fa-shield-alt",
        })) : empty())))));
        return react.createElement(react.Fragment, {}, ...xs_3);
    }
    else {
        return createElement("span", {
            children: x.Username,
        });
    }
}

